import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ba7c68e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "design" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "el-upload-list__item-actions" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "el-upload__tip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ZoomIn = _resolveComponent("ZoomIn")!
  const _component_Download = _resolveComponent("Download")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.mode === 'DESIGN')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_icon, { size: "1em" }, {
              default: _withCtx(() => [
                _createVNode(_component_Plus)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("p", null, _toDisplayString(_ctx.placeholder) + " " + _toDisplayString(_ctx.sizeTip), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_el_upload, {
            "file-list": _ctx._value,
            action: "#",
            limit: _ctx.maxSize,
            "with-credentials": "",
            multiple: _ctx.maxSize > 0,
            data: _ctx.uploadParams,
            "list-type": "picture-card",
            "before-upload": _ctx.beforeUpload
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { size: "1em" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Plus)
                ]),
                _: 1
              })
            ]),
            file: _withCtx(({ file }) => [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  class: "el-upload-list__item-thumbnail",
                  src: file.url,
                  alt: ""
                }, null, 8, _hoisted_4),
                _createElementVNode("span", _hoisted_5, [
                  _createElementVNode("span", {
                    class: "el-upload-list__item-preview",
                    onClick: ($event: any) => (_ctx.handlePictureCardPreview(file))
                  }, [
                    _createVNode(_component_el_icon, { size: "1em" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ZoomIn)
                      ]),
                      _: 1
                    })
                  ], 8, _hoisted_6),
                  (!_ctx.disabled)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: "el-upload-list__item-delete",
                        onClick: ($event: any) => (_ctx.handleDownload(file))
                      }, [
                        _createVNode(_component_el_icon, { size: "1em" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Download)
                          ]),
                          _: 1
                        })
                      ], 8, _hoisted_7))
                    : _createCommentVNode("", true),
                  (!_ctx.disabled)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: "el-upload-list__item-delete",
                        onClick: ($event: any) => (_ctx.handleRemove(file))
                      }, [
                        _createVNode(_component_el_icon, { size: "1em" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Delete)
                          ]),
                          _: 1
                        })
                      ], 8, _hoisted_8))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            tip: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.placeholder) + " " + _toDisplayString(_ctx.sizeTip), 1)
            ]),
            _: 1
          }, 8, ["file-list", "limit", "multiple", "data", "before-upload"])
        ]))
  ]))
}